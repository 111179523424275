<template>
	<div>
		<DataTable
			scroll
			:async="pagination"
			:loading="loading"
			:colunas="parsedCols"
			:linhas="linhas"
			:errMsg="errMsg"
			:state="dataTableState"
			@state-change="setStateDataTable"
			@edicao-variavel="(itemId) => $emit('edicao-variavel', itemId)"
			@salvar-edicao="(itemId) => $emit('salvar-edicao', itemId)"
			@salvar-adicao="(itemId) => $emit('salvar-adicao', itemId)"
		>
			<template #results-page v-if="pagination">
				<select
					class="custom-select ml-1"
					:value="results"
					@input="changeResults"
					data-cy="resultados-pagina"
				>
					<option value="10">10 por página</option>
					<option value="20">20 por página</option>
					<option value="50">50 por página</option>
					<option value="100">100 por página</option>
					<option v-if="pagination" value="-1">
						Mostrar todos
					</option>
				</select>
			</template>
		</DataTable>
		<Paginator
			:page="page"
			:pages="pages"
			:count="count"
			:disabled="loading"
			:first="first"
			:last="last"
			@paginate="paginate"
		/>
	</div>
</template>

<script>
	import DataTable from "@/components/DataTable";
	import Paginator from "@/components/Paginator";

	import { VariaveisService } from "../services/variaveis";
	import { GruposService } from "../services/grupos";

	export default {
		name: "GenericList",
		components: {
			DataTable,
			Paginator
		},

		props: {
			item: Object,
			title: {
				type: String,
				required: true
			},

			dataTableStateName: {
				type: String,
				required: true
			},

			lista: {
				type: Array
			},

			linhas: {
				type: Array
			},

			cols: {
				type: Array,
				required: true
			},

			colsName: {
				type: Array,
				required: true
			},

			canShowAllResults: {
				type: Boolean,
				default: false
			},

			showInativos: {
				type: Boolean,
				default: false
			}
		},

		data () {
			return {
				errMsg: "",

				pagination: false,
				page: 0,
				pages: 1,
				results: 20,
				first: 0,
				last: 0,
				count: 0,
				loading: false,

				gruposService: new GruposService(),
				variaveisService: new VariaveisService()
			};
		},

		mounted () {},

		computed: {
			dataTableState () {
				return this.$store.state.dataTables[
					"dataTable" + this.dataTableStateName
				];
			},

			parsedCols () {
				return this.cols.map((c, idx) => this.colsName[idx] ? c : { value: c, sortable: false });
			}
		},

		methods: {
			setStateDataTable (...args) {
				this.$store.commit(
					"dataTables/setStateDataTable" + this.dataTableStateName,
					...args,
				);
				this.page = 0;
			},

			changeResults (event) {
				this.page = 0;
				this.update(1, event.target.value);
			},

			paginate (p) {
				if (p === this.page) return;
				this.page = p;

				this.update(this.page, this.results);
			},

			refreshProps () {
				return; // função serve para forçar o componente a atualizar os parâmetros antes de chamar um update
			},

			update (page = 1, results = 20) {
				this.loading = false;
			}
		}
	};
</script>

<style scoped>
	.custom-select {
		width: auto;
	}

	.form-control {
	height: auto !important;
	}

	.feather {
		margin-bottom: 4px;
	}
</style>
