<template>
	<div>
		<div class="d-flex">
			<h3 class="card-tittle mb-3">Lista de variáveis</h3>
			<div class="spacer"></div>
		</div>

		<div id="wrapper1">
			<div id="divScrollTop"></div>
		</div>

		<div id="wrapper2">
			<div id="divScrollBottom" class="table-responsive">
				<table class="table table-hover mb-2" :class="{ 'condensed': condensed }" data-cy="Tabela" :data-asc="sortAsc">
					<thead>
						<tr>
							<th v-if="!noedit" data-editar>Ações</th>
							<th scope="col" v-for="(col, idx) in colunas"
								:key="idx"
								@click="sortC(col, idx)"
								:data-active="sortBy==idx"
								:data-ordenar="typeof col === 'string' || col.sortable !== false"> {{ typeof col === 'string' ? col : (col.value || '-') }}
							</th>
						</tr>
					</thead>
					<tbody v-if="loading">
						<tr v-for="i in 3" :key="i">
							<td class="p-1" v-for="j in colunas.length + 1" :key="j">
								<tb-skeleton shape="rect" style="background-color: #dcdcdc; height: 2rem; width:100%" theme="opacity"></tb-skeleton>
							</td>
						</tr>
					</tbody>
					<tbody v-else-if="sortedItems.length === 0">
						<tr>
							<td scope="row" class="text-center" :colspan="colunas.length + 1">{{errMsg || '(Vazio)'}}</td>
						</tr>
					</tbody>
					<tbody v-else>
						<tr v-for="item in sortedItems" :key="item.cols[0]" :style="getStyle(item)">
							<td v-if="!noedit" data-editar class="align-middle">
								<button
									data-cy="editar"
									@click.stop="$emit('edicao-variavel', item.cols[0])"
									class="btn btn-sm btn-outline-light text--black"
									title="Editar">
									<Edit3Icon size="20" alt="Editar"/>
								</button>
							</td>
							<th scope="row">
								<slot :name="typeof colunas[0] === 'string' ? colunas[0] : (colunas[0].value || '')" :value="item.cols[0]" :item="item">
									{{ item.cols[0] }}
								</slot>
							</th>
							<td v-for="coli in item.cols.length-1" :key="coli">
								<slot :name="typeof colunas[coli] === 'string' ? colunas[coli] : (colunas[coli].value || '')" :value="item.cols[coli]" :item="item">
									{{ (item.cols[coli] || item.cols[coli] === 0) ? item.cols[coli] : '-' }}
								</slot>
							</td>
						</tr>
					</tbody>
				</table>
			</div>
		</div>
	</div>
</template>

<script>
	const removeAccents = require("remove-accents");
	const argToString = str => str == null ? "-" : removeAccents(str.toString().toLowerCase());
	const isQueryInString = query => str => argToString(str).includes(query);
	const parseQuery = query => removeAccents(query.trim().toLowerCase());

	export default {
		name: "DataTable",
		props: {
			item: Object,
			colunas: {
				type: Array,
				required: true
			},

			linhas: {
				type: Array,
				required: true
			},

			errMsg: {
				type: String,
				default: ""
			},

			loading: {
				type: Boolean,
				default: false
			},

			state: {
				type: Object,
				dafault: () => ({
					sortBy: 0,
					sortAsc: true,
					query: ""
				})
			},

			async: {
				type: Boolean,
				default: false
			},

			nosearch: {
				type: Boolean,
				default: false
			},

			scroll: {
				type: Boolean,
				default: false
			},

			noedit: {
				type: Boolean,
				default: false
			},

			condensed: {
				type: Boolean,
				default: false
			}
		},

		data () {
			const iState = this.state ? { ...this.state } : {
				sortBy: 0,
				sortAsc: true,
				query: ""
			};

			return {
				iState,
				q: parseQuery(iState.query)
			};
		},

		computed: {
			sortBy: {
				get () {
					return this.iState.sortBy;
				},

				set (v) {
					this.iState.sortBy = v;
				}
			},

			sortAsc: {
				get () {
					return this.iState.sortAsc ? 1 : -1;
				},

				set (v) {
					this.iState.sortAsc = v === 1;
				}
			},

			query: {
				get () {
					return this.iState.query;
				},

				set (v) {
					this.iState.query = v;
				}
			},

			sortedItems () {
				if (this.async) return this.linhas || [];

				let result = [];
				if (this.q === "") {
					result = this.linhas || [];
				}

				else {
					result = this.linhas && this.linhas.filter(l => l.cols.some(isQueryInString(this.q)));
				}

				return result.sort((a,b) => (
					a.cols[this.sortBy] < b.cols[this.sortBy] ? this.sortAsc * (-1)
					: (a.cols[this.sortBy] > b.cols[this.sortBy]) ? this.sortAsc * 1
						: 0
				));
			}
		},

		methods: {
			emitState () {
				this.$emit("state-change", { ...this.iState });
			},

			sortC (col, by) {
				if (typeof col === "string" || col.sortable !== false) {
					this.sortAsc = (this.sortBy == by) ? this.sortAsc * (-1) : 1;
					this.sortBy = by;

					this.emitState();
				}
			},

			updateState () {
				this.iState = { ...this.state };
			},

			getStyle (item) {
				const style = {};
				if (item.color) style.color = item.color;
				if (item.backgroundColor) style.backgroundColor = item.backgroundColor;
				return style;
			}
		},

		watch: {
			"state.query": "updateState",
			"state.sortBy": "updateState",
			"state.sortAsc": "updateState"
		},

		mounted () {
			const wrapper1 = document.getElementById("wrapper1");
			const wrapper2 = document.getElementById("wrapper2");

			wrapper1.onscroll = function () {
				wrapper2.scrollLeft = wrapper1.scrollLeft;
			};

			wrapper2.onscroll = function () {
				wrapper1.scrollLeft = wrapper2.scrollLeft;
			};
		}
	};
</script>

<style scoped>
	table thead {
		background-color: #f5f6fa;
	}

	table thead th {
		user-select: none;
	}

	table thead th[data-ordenar] {
		cursor: pointer;
		user-select: none;
	}

	table thead th[data-active] {
		color: #007bff;
	}

	table thead th[data-ordenar]::after {
		content: ' ↕';
		color: #66666680;
	}

	table[data-asc="1"] thead th[data-active]::after {
		content: ' ↑';
		color: inherit;
	}

	table[data-asc="-1"] thead th[data-active]::after {
		content: ' ↓';
		color: inherit;
	}

	thead th {
		white-space: nowrap;
		text-overflow: ellipsis;
	}

	[data-editar] {
		width: 8rem;
		text-align: center;
	}

	.container-pesquisa {
		display: flex;
		justify-content: space-between;
		align-items: center;
	}

	.text--black {
		color: black;
	}

	.form-control {
		height: calc(1.5em + 0.75rem + 4px);
	}

	.table-responsive {
		white-space: nowrap;
	}

	.table.condensed td,
	.table.condensed th {
		padding: 0.25rem 0.5rem;
	}

	@media (max-width: 500px) {
		.container-pesquisa.mb-1 {
			flex-direction: column;
			align-items: center;
		}

		.custom-select.ml-1 {
			margin: 0.25rem;
		}
	}

	#wrapper1, #wrapper2 {
		width: auto;
		border: none 0px;
		overflow-x: scroll;
		overflow-y:hidden;
	}

	#wrapper1 {
		height: 20px;
	}

	#wrapper2 {
		height: auto;
	}

	#divScrollTop {
		width: 100%;
		min-width: 1500px;
		height: 20px;
	}

	#divScrollBottom {
		width: 100%;
		min-width: 1500px;
		height: auto;
		overflow: visible;
		margin-bottom: -10px;
	}
</style>
