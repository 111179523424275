<template>
<two-cards>
		<template #header>
			<h4 class="card-title">Filtros</h4>
			<slot>
				<button
					@click="mostrarAdicao"
					v-if="possuiPermissao('GER_I_VARIAVEIS')"
					class="btn btn-success text-white align-self-center"
				>
					Adicionar variável
				</button>
			</slot>
		</template>
		<template #one>
			<form class="mt-2" @submit.prevent="filtroVariaveis">
				<div class="form-row">
					<div class="col form-group col-sm-6">
						<label for="data-final">Tag: </label>
						<b-form-input
							v-model="tagInput"
						></b-form-input>
					</div>
					<div class="col form-group col-sm-6">
						<label for="data-inicial">Descrição: </label>
						<b-form-input
							v-model="descricaoInput"
						></b-form-input>
					</div>
				</div>
				<div class="form-row">
					<div class="form-group col-sm-3">
						<label class="mt-2">Área </label>
							<vue-multi-select
								class="multi-100"
								v-model="areaSelecionada"
								@input="checarArea"
								search
								historyButton
								:filters="multiSelectFilters[0]"
								searchPlaceholder="Pesquisar"
								:options="{ multi: false, labelName: 'nome' }"
								:selectOptions="areasOpcoes"
								:btnLabel="() => (areaSelecionada[0] && areaSelecionada[0].nome) || 'Selecione'"
							/>
					</div>
					<div class="form-group col-sm-3">
						<label class="mt-2">Máquina </label>
							<vue-multi-select
								class="multi-100"
								v-model="maquinasSelecionadas"
								:disabled="!areaSelecionada[0]"
								@input="checarMaquina"
								search
								historyButton
								:filters="multiSelectFilters[1]"
								:options="{ multi: true, labelName: 'nome' }"
								:selectOptions="maquinasOpcoes"
								:btnLabel="() => customLabel(maquinasSelecionadas)"
							/>
					</div>
					<div class="form-group col-sm-3">
						<label class="mt-2">Grupo </label>
							<vue-multi-select
								class="multi-100"
								v-model="gruposSelecionados"
								search
								historyButton
								:filters="multiSelectFilters[2]"
								:options="{ multi: true }"
								:selectOptions="gruposOpcoes"
								:btnLabel="() => customLabel(gruposSelecionados)"
							/>
					</div>
					<div class="form-group col-sm-3">
						<label class="mt-2">Tipo</label>
							<vue-multi-select
								class="multi-100"
								v-model="tiposVariaveis"
								search
								historyButton
								:filters="multiSelectFilters[3]"
								:options="{ multi: true }"
								:selectOptions="tiposOpcoes"
								:btnLabel="() => customLabel(tiposVariaveis)"
							/>
					</div>
				</div>
				<div class="d-flex justify-content-end tcs-card-footer-action">
					<button
						class="btn btn-success tcs-btn"
						type="search"
					>
						<SearchIcon />
					</button>
				</div>
			</form>
		</template>
		<template #two>
			<GenericList
				title="variáveis"
				ref="lista"
				data-table-state-name="Variavel"
				@edicao-variavel="mostrarEdicao"
				:cols="['#', 'Tag', 'Descrição', 'Tipo', 'Grupo', 'Taxa de Amostragem', 'Variação Mínima', 'Máquina', 'Área']"
				:cols-name="['id', 'tag', 'descricao', 'tipo', 'grupo', 'taxaAmostragem', 'varMinima', 'maquina', 'area']"
				:linhas="linhas"
			/>

			<b-modal ref="modal" hide-footer title="Gestão de variável" size="lg">
				<AddEditVariavel
					:itemId="itemId"
					@cancelar="cancelar"
					@salvar-edicao="salvarModal"
					@salvar-adicao="salvarModal"
					@remover-variavel="remover"
				/>
			</b-modal>
		</template>
	</two-cards>
</template>


<script>
	import TwoCards from "../../templates/TwoCards.vue";

	import VueMultiSelect from "vue-multi-select";
	import "vue-multi-select/dist/lib/vue-multi-select.css";

	import GenericList from "@/components/GenericList";
	import AddEditVariavel from "@/components/Edit.vue";

	import { GruposService } from "../../services/grupos";
	import { MaquinasService } from "../../services/maquinas";
	import { AreasService } from "../../services/areas";
	import { VariaveisService } from "../../services/variaveis";
	import { possuiPermissao } from "../../helpers/permissions";

	export default {
		components: {
			TwoCards,
			VueMultiSelect,
			GenericList,
			AddEditVariavel
		},

		props: {
			update: {
				type: Function
			}
		},

		data () {
			return {
				itemId: 0,
				grupos: [],
				areas: [],
				maquinas: [],
				variaveis: [],

				tagInput: "",
				descricaoInput: "",

				loading: false,

				lista: [],

				gruposSelecionados: [],
				gruposOpcoes: [],

				areaSelecionada: [],
				areasOpcoes: [],

				maquinasSelecionadas: [],
				maquinasOpcoes: [],

				tiposVariaveis: [],
				tiposOpcoes: [],

				multiSelectFilters: [
					this.getMultiSelectFilters(),
					this.getMultiSelectFilters(true),
					this.getMultiSelectFilters(),
					this.getMultiSelectFilters()
				],

				gruposService: new GruposService(),
				areasService: new AreasService(),
				maquinasService: new MaquinasService(),
				variaveisService: new VariaveisService(),
				possuiPermissao
			};
		},

		computed: {
			linhas () {
				return this.lista.map(row => {
					const group = this.grupos.find(g => g.id === row.id_grupo) || "-";
					const machine = this.maquinas.find(m => m.id === group.id_maquina) || "-";
					const area = this.areas.find(a => a.id === machine.id_area);

					return ({
						href: { path: row.id },
						cols: [
							row.id,
							row.tag,
							row.descricao,
							row.tipo === "ANALOGICA" ? "Analógica" : "Digital",
							group.nome,
							row.taxa_amostragem + " ms",
							row.variacao_minima,
							(machine || { nome: "-" }).nome,
							(area || { nome: "-" }).nome
						]
					});
				});
			}
		},

		async mounted () {
			this.getOptions();
		},

		methods: {
			fecharModal () {
				this.$refs.modal.hide();
			},

			mostrarEdicao (itemId) {
				this.itemId = itemId;
				this.$refs.modal.show();
			},

			mostrarAdicao () {
				this.itemId = 0;
				this.$refs.modal.show();

				this.fecharModal();
			},

			cancelar () {
				this.loading = false;
				this.fecharModal();
			},

			salvarModal () {
				this.loading = true;

				this.filtroVariaveis();
				this.fecharModal();
			},

			async remover () {
				this.filtroVariaveis();
				this.fecharModal();
			},

			customLabel (selected) {
				return selected.length
					? `${selected.length} selecionado${
						selected.length > 1 ? "s" : ""
					}`
					: "Selecione";
			},

			getMultiSelectFilters (feminino) {
				return [{
					nameAll: `Selecionar tod${feminino ? "a" : "o"}s`,
					nameNotAll: `Desselecionar tod${feminino ? "a" : "o"}s`,
					func () {
						return true;
					}
				}];
			},

			async getOptions () {
				this.lista = (await this.variaveisService.listVariables());

				this.grupos = (await this.gruposService.listGroups() || []).map(g => ({
					name: g.nome,
					...g
				}));
				this.gruposOpcoes = this.grupos;

				this.areas = (await this.areasService.listAreas() || []).map(a => ({
					name: a.nome,
					...a
				}));
				this.areasOpcoes = this.areas;

				this.maquinas = (await this.maquinasService.listMachines() || []).map(m => ({
					name: m.nome,
					...m
				}));
				this.maquinasOpcoes = this.maquinas;

				this.variaveis = (await this.variaveisService.listVariables() || []).map(v => ({
					name: v.name,
					...v
				}));

				this.tiposOpcoes = ["Analógica", "Digital"];
			},

			async checarArea () {
				if (this.areaSelecionada[0])
					this.maquinasOpcoes = this.maquinas.filter(m => this.areaSelecionada[0] && m.id_area == this.areaSelecionada[0].id);

				if (!this.areaSelecionada[0] && this.maquinasSelecionadas[0])
					this.maquinasSelecionadas = [];
				else
					this.gruposOpcoes = this.grupos;
			},

			async checarMaquina () {
				const idMaquina = this.maquinasSelecionadas.map(g => g.id);
				const idMaquinasOpcoes = this.maquinasOpcoes.map(m => m.id);

				if (this.maquinasSelecionadas[0])
					this.gruposOpcoes = this.grupos.filter(g => idMaquina.includes(g.id_maquina));

				if (!this.maquinasSelecionadas[0] && this.areaSelecionada.length)
					this.gruposOpcoes = this.grupos.filter(g => idMaquinasOpcoes.includes(g.id_maquina));
			},

			async filtroVariaveis () {
				try {
					this.loading = true;

					let idGrupos = null;
					if (!this.gruposSelecionados[0] && this.maquinasSelecionadas[0]) {
						idGrupos = this.gruposOpcoes.map(m => m.id);
					}

					if (!this.gruposSelecionados[0] && !this.maquinasSelecionadas[0] && this.areaSelecionada[0]) {
						const idMaquinas = this.maquinasOpcoes.map(m => m.id);
						const grupo = this.gruposOpcoes.filter(g => idMaquinas.includes(g.id_maquina));

						if (!grupo) {
							idGrupos = this.gruposOpcoes.filter(g => idMaquinas.includes(!g.id_maquina));
						}

						idGrupos = grupo.map(g => g.id);
					}

					if (this.gruposSelecionados[0]) {
						idGrupos = this.gruposSelecionados.map(g => g.id);
					}

					const tipos = this.tiposVariaveis.map(t => t === "Analógica" ? t = "ANALOGICA" : "DIGITAL");
					const tag = this.tagInput;
					const descricao = this.descricaoInput;

					if (
						(!this.gruposOpcoes.length && this.maquinasSelecionadas.length && this.areaSelecionada.length) ||
						(this.areaSelecionada.length && !this.gruposOpcoes.length)
					) {
						this.lista = [];
					} else {
						this.lista = (await this.variaveisService.listVariables(idGrupos, tipos, tag, descricao)) || [];
					}
				} catch (err) {
					console.log(err);

					this.$swal({
						title: "Falha ao fazer a busca!",
						html: `<p>${err.response ? err.response.data.error : err.message}</p>`,
						confirmButtonText: "Fechar",
						confirmButtonColor: "#6c757d"
					});
				}
			}
		}
	};
</script>
